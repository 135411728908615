import { apiFetch } from './APIFetcher';

/**
 * fetches array of urls (possibly non-unique) in parallel
 * @param {Array} urls array of urls to fetch
 * @param {number} limit limit of parallel requests
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const get = (urls: string[], limit = 5, callback: (r: any[]) => void, userToken: string): void => {
  const cache: { [url: string]: Response } = {},
    workers = [];
  let currentNr = Math.min(urls.length, limit);

  const getUrl = (i: number): Promise<void | Response> => {
    if (i >= urls.length) {
      return Promise.resolve();
    }

    if (urls.indexOf(urls[i]) < i) {
      return getUrl(currentNr++);
    }

    return apiFetch(urls[i], 'GET', null, userToken).then((r) => {
      cache[urls[i]] = r;
      return getUrl(currentNr++);
    });
  };

  for (let i = 0; i < currentNr; i++) {
    workers.push(getUrl(i));
  }

  Promise.all(workers).then(() => callback(urls.map((url) => cache[url])));
};

/**
 * fetches array of urls (possibly non-unique) in parallel
 * @param {Array} urls array of urls to fetch
 * @param {number} limit limit of parallel requests
 */
export const getIndividually = <T extends { url: string }>(
  urls: T[],
  limit = 5,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (r: T & { response: any }) => void,
  userToken: string
): Promise<(Response | void)[]> => {
  const workers = [];
  let currentNr = Math.min(urls.length, limit);

  const getUrl = (i: number): Promise<void | Response> => {
    if (i >= urls.length) {
      return Promise.resolve();
    }

    if (urls.indexOf(urls[i]) < i) {
      return getUrl(currentNr++);
    }

    return apiFetch(urls[i].url, 'GET', null, userToken).then((r) => {
      callback({ ...urls[i], response: r });
      return getUrl(currentNr++);
    });
  };

  for (let i = 0; i < currentNr; i++) {
    workers.push(getUrl(i));
  }

  return Promise.all(workers);
};
