import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as H from 'history';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react';
import Loader from 'react-loader-spinner';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { apiFetch } from '../util/APIFetcher';
import { dateToDMYHHMM } from '../util/Formatters';
import { dateXDaysAgo, roundTo } from '../util/Funcs';
import { buildHandleInputChange } from '../util/InputChangeHandler';
import { getIndividually } from '../util/ParallelFetcher';
import './styles/VehicleOverviewScreen.scss';

export type VehicleDataSummary = {
  loading: boolean;
  update_frequency?: number;
  last_update?: {
    timestamp: string;
    external_voltage: number;
    analog_input_1: number;
    analog_input_2: number;
    digital_input_1: number;
  };
  summary_overall?: {
    distance_tot: number;
    duration_tot: number;
  };
  subscription?: string;
  timestamp_installation?: string;
};

type VehicleOverviewScreenState = {
  vehicleDataSummaries: { [id: number]: VehicleDataSummary };
  loadingDataOf: Set<number>;
};

export default class VehicleOverviewScreen extends React.Component<
  RouteComponentProps & AppProps,
  VehicleOverviewScreenState
> {
  state: VehicleOverviewScreenState = {
    vehicleDataSummaries: {},
    loadingDataOf: new Set(),
  };
  handleInputChange = buildHandleInputChange(this);

  componentDidMount(): void {
    // this.refresh();
  }

  componentDidUpdate = (prevProps: RouteComponentProps & AppProps): void => {
    if (this.props.appState.vehicleProfiles.length !== prevProps.appState.vehicleProfiles.length) {
      // this.refresh();
    }
  };

  refresh(): void {
    const vehicleDataSummaries: { [id: number]: VehicleDataSummary } = {};
    const urls = [];
    const loadingDataOf = this.state.loadingDataOf;
    for (const vehicleProfile of this.props.appState.vehicleProfiles) {
      vehicleDataSummaries[vehicleProfile.id] = { loading: true };
      urls.push({ url: `/api/v1.0/admin/vehicle/${vehicleProfile.id}/data-summary` });
      loadingDataOf.add(vehicleProfile.id);
    }
    this.setState({
      vehicleDataSummaries: vehicleDataSummaries,
      loadingDataOf: loadingDataOf,
    });

    getIndividually(
      urls,
      10,
      (r) => {
        const vehicleDataSummaries = this.state.vehicleDataSummaries;
        vehicleDataSummaries[r.response['data']['vehicle']['id']] = r.response['data'];
        const loadingDataOf = this.state.loadingDataOf;
        loadingDataOf.delete(r.response['data']['vehicle']['id']);
        this.setState({
          vehicleDataSummaries: vehicleDataSummaries,
          loadingDataOf: loadingDataOf,
        });
      },
      this.props.appState.userToken ?? ''
    );
  }

  refreshSingle(vehicleId: number): void {
    const userToken = this.props.appState.userToken;
    const loadingDataOf = this.state.loadingDataOf;
    loadingDataOf.add(vehicleId);
    this.setState({ loadingDataOf: loadingDataOf });

    apiFetch(`/api/v1.0/admin/vehicle/${vehicleId}/data-summary`, 'GET', null, userToken).then((data) => {
      const vehicleDataSummaries = this.state.vehicleDataSummaries;
      vehicleDataSummaries[vehicleId] = data['data'];
      const loadingDataOf = this.state.loadingDataOf;
      loadingDataOf.delete(vehicleId);
      this.setState({
        vehicleDataSummaries: vehicleDataSummaries,
        loadingDataOf: loadingDataOf,
      });
    });
  }

  selectVehicle = (history: H.History, vehicleId: number): void => {
    history.push(`/vehicle/detail/${vehicleId}`);
  };

  render(): React.ReactElement {
    const vehicles = this.props.appState.vehicleProfiles
      .sort((vp1, vp2) => vp1.id - vp2.id)
      .map((vehicleProfile) => {
        const loading = this.state.loadingDataOf.has(vehicleProfile.id);
        const dataAvailable = this.state.vehicleDataSummaries[vehicleProfile.id] && !loading;

        let freqTooHigh = false;
        const update_frequency = this.state.vehicleDataSummaries[vehicleProfile.id]?.update_frequency;
        if (dataAvailable && update_frequency) {
          freqTooHigh = update_frequency > 300;
        }

        let lastUpdateTooLongAgo = false;
        const last_update = this.state.vehicleDataSummaries[vehicleProfile.id]?.last_update;
        if (dataAvailable && last_update) {
          lastUpdateTooLongAgo = new Date(last_update.timestamp) < dateXDaysAgo(new Date(), 7);
        }

        const summary_overall = this.state.vehicleDataSummaries[vehicleProfile.id]?.summary_overall;
        const timestamp_installation = this.state.vehicleDataSummaries[vehicleProfile.id]?.timestamp_installation;

        // prettier-ignore
        return <tr key={vehicleProfile.id}>
          <td>
            {loading && <Loader type="Oval" color="#4978BC" height={12} width={12} />}
            {!loading && <FontAwesomeIcon icon={faDownload} style={{ opacity: 0.5, cursor: 'pointer' }} onClick={() => this.refreshSingle(vehicleProfile.id)}></FontAwesomeIcon>}
          </td>
          <td>
            {vehicleProfile.id}<span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>{vehicleProfile.vehicleTpe}</span>
          </td>
          <Route render={({ history }) => (
            <td style={{ cursor: 'pointer' }} onClick={() => this.selectVehicle(history, vehicleProfile.id)}>
              {vehicleProfile.name}<span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>{vehicleProfile.tpe}</span>
            </td>
          )} />

          <td>
            {vehicleProfile.iotName}
          </td>
          <td>
            {dataAvailable && last_update && (last_update.external_voltage !== 0) && roundTo(last_update.external_voltage / 1000, 2)}<span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>V</span>
          </td>
          <td>
            {dataAvailable && last_update && (last_update.analog_input_1 !== 0) && last_update.analog_input_1}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>{vehicleProfile.ain1Type}</span>
          </td>
          <td>{dataAvailable && last_update && (last_update.analog_input_2 !== 0) && last_update.analog_input_2}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>{vehicleProfile.ain2Type}</span>
          </td>
          <td>{dataAvailable && last_update && last_update.digital_input_1}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>{vehicleProfile.din1Type}</span>
          </td>
          <td>
            {dataAvailable && summary_overall && (summary_overall['distance_tot'] !== 0) && roundTo(summary_overall['distance_tot'] / 1000, 2)}<span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>m</span>
          </td>
          <td>
            {dataAvailable && summary_overall && (summary_overall['duration_tot'] !== 0) && roundTo(summary_overall['duration_tot'] / 3600, 1)}<span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>h</span>
          </td>
          <td>
            {dataAvailable && this.state.vehicleDataSummaries[vehicleProfile.id]['subscription']}
          </td>
          <td>
            {dataAvailable && timestamp_installation && new Date(timestamp_installation) > new Date(2000, 1) && dateToDMYHHMM(new Date(timestamp_installation))}
          </td>
          <td style={{ backgroundColor: freqTooHigh ? '#ffad9f' : 'initial' }}>
            {dataAvailable &&update_frequency && roundTo(update_frequency, 1)}
          </td>
          <td style={{ backgroundColor: lastUpdateTooLongAgo ? '#ffad9f' : 'initial' }}>
            {dataAvailable && last_update && dateToDMYHHMM(new Date(last_update['timestamp']))}
          </td>
        </tr>;
      });

    return (
      <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'move' } }}>
        <div className="vehicle-overview-component">
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <h3>Vehicles on c.technology platform</h3>
              <span>
                <div className="btn btn-primary" onClick={() => this.refresh()}>
                  Load all
                </div>
              </span>
            </div>
            <p>
              Use this tab to monitor the status of vehicles (if they send data, when they last sent data, etc.).
              Clicking on a vehicle lets you see the raw data sent by this vehicle.
            </p>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Vehicle Name</th>
                  <th scope="col">IoT Name</th>
                  <th scope="col">Bat.</th>
                  <th scope="col">AIN1</th>
                  <th scope="col">AIN2</th>
                  <th scope="col">DIN1</th>
                  <th scope="col">Distance</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Subscription</th>
                  <th scope="col">Inst. Date</th>
                  <th scope="col">Freq. (24h)</th>
                  <th scope="col">Last Upd.</th>
                </tr>
              </thead>
              <tbody>{vehicles}</tbody>
            </table>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    );
  }
}
