import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { RouteComponentProps } from 'react-router-dom';
import { AppProps } from '../App';
import { VehicleProfile } from '../AppState';
import { apiFetch } from '../util/APIFetcher';
import { roundTo } from '../util/Funcs';
import { buildHandleInputChange } from '../util/InputChangeHandler';
import './styles/VehicleOverviewScreen.scss';

type VehicleUpdate = {
  id: string;
  vehicle_id: string;
  timestamp: string;
  priority?: number;
  longitude: number;
  latitude: number;
  altitude?: number;
  angle?: number;
  num_satellites: number;
  speed: number;
  movement: number;
  sleep_mode: number;
  gnss_pdop: number;
  gnss_hdop: number;
  external_voltage: number;
  battery_voltage: number;
  battery_current: number;
  analog_input_1: number;
  analog_input_2: number;
  digital_input_1: number;
  total_odometer: number;
  ble_temp_1: number;
  ble_temp_2: number;
  ble_temp_3: number;
  ble_temp_4: number;
};

type VehicleOverviewScreenState = {
  vehicleProfile: VehicleProfile | undefined;
  updates: VehicleUpdate[];
  limit: number;
};

export default class VehicleOverviewScreen extends React.Component<
  RouteComponentProps<{ id: string }> & AppProps,
  VehicleOverviewScreenState
> {
  state: VehicleOverviewScreenState = {
    vehicleProfile: undefined,
    updates: [],
    limit: 20,
  };
  handleInputChange = buildHandleInputChange(this);

  componentDidMount(): void {
    this.setState({
      vehicleProfile: this.props.appState.vehicleProfiles.find(
        (vp) => vp.id === parseInt(this.props.match.params['id'])
      ),
    });
    this.refresh();
  }

  componentDidUpdate = (prevProps: RouteComponentProps<{ id: string }> & AppProps): void => {
    if (
      prevProps.match.params['id'] !== this.props.match.params['id'] ||
      this.props.appState.vehicleProfiles.length !== prevProps.appState.vehicleProfiles.length
    ) {
      this.setState({
        vehicleProfile: this.props.appState.vehicleProfiles.find(
          (vp) => vp.id === parseInt(this.props.match.params['id'])
        ),
      });
      this.refresh();
    }
  };

  refresh = (): void => {
    apiFetch(
      `/api/v1.0/admin/vehicle/${this.props.match.params['id']}/data?limit=${this.state.limit}`,
      'GET',
      null,
      this.props.appState.userToken
    ).then((res) => res['data'] && this.setState({ updates: res['data'] }));
  };

  render(): React.ReactElement {
    const tableRowsUpdates = this.state.updates.map((u) => {
      return (
        <tr key={u['id']}>
          <td>{u['id']}</td>
          <td>{u['timestamp'].replace('T', ' ').replace('Z', '').split('.')[0]}</td>
          <td>{u['priority']}</td>
          <td>
            {roundTo(u['longitude'], 3)}/{roundTo(u['latitude'], 3)}/{u['altitude']}
          </td>
          <td>{u['angle']}</td>
          <td>{u['num_satellites']}</td>
          <td>{u['speed']}</td>
          <td>{u['movement']}</td>
          <td>{u['sleep_mode']}</td>
          <td>{u['gnss_pdop']}</td>
          <td>{u['gnss_hdop']}</td>
          <td>{u['external_voltage']}</td>
          <td>{u['battery_voltage']}</td>
          <td>{u['battery_current']}</td>
          <td>{u['analog_input_1']}</td>
          <td>{u['analog_input_2']}</td>
          <td>{u['digital_input_1']}</td>
          <td>
            {roundTo(u['total_odometer'] / 1000, 1)}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>km</span>
          </td>
          <td>
            {u['ble_temp_1']}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>°</span>
          </td>
          <td>
            {u['ble_temp_2']}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>°</span>
          </td>
          <td>
            {u['ble_temp_3']}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>°</span>
          </td>
          <td>
            {u['ble_temp_4']}
            <span style={{ paddingLeft: 4, fontSize: 10, opacity: 0.4 }}>°</span>
          </td>
        </tr>
      );
    });

    return (
      <div className="vehicle-overview-component">
        <div>
          <h3>Individual Updates from {this.state.vehicleProfile?.name}</h3>

          <div style={{ marginBottom: 12 }}>
            <input
              type="number"
              className="form-control"
              id="limit"
              name="limit"
              value={this.state.limit}
              onChange={this.handleInputChange}
              style={{
                marginLeft: 4,
                marginRight: 4,
                width: 'auto',
                display: 'inline-block',
              }}
            ></input>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled" placement="bottom">
                  Reload {this.state.limit} data points.
                </Tooltip>
              }
            >
              <button
                className="btn btn-primary"
                style={{ verticalAlign: 'top', marginRight: 4 }}
                onClick={this.refresh}
              >
                Refresh
              </button>
            </OverlayTrigger>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Time</th>
                <th scope="col">Prio.</th>
                <th scope="col">Lon./Lat./Alt.</th>
                <th scope="col">Angle</th>
                <th scope="col">#Sat.</th>
                <th scope="col">Speed</th>
                <th scope="col">Move.</th>
                <th scope="col">Sleep</th>
                <th scope="col">PDOP</th>
                <th scope="col">HDOP</th>
                <th scope="col">ExtV.</th>
                <th scope="col">BatV.</th>
                <th scope="col">BatC.</th>
                <th scope="col">AIN1</th>
                <th scope="col">AIN2</th>
                <th scope="col">DIN1</th>
                <th scope="col">Tot. Od.</th>
                <th scope="col">T1</th>
                <th scope="col">T2</th>
                <th scope="col">T3</th>
                <th scope="col">T4</th>
              </tr>
            </thead>
            <tbody>{tableRowsUpdates}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
