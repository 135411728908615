/**
 * Creates a new date that is a number of days ago.
 *
 * @param initialDate The date from which to start.
 * @param numDays The number of days to go back in time.
 * @returns A new date, numDays days ago.
 */
export const dateXDaysAgo = (initialDate: Date, numDays: number): Date => {
  const copiedDate = new Date(initialDate.getTime());
  copiedDate.setDate(initialDate.getDate() - numDays);
  return copiedDate;
};

/**
 * Creates a new date that is in a number of days.
 *
 * @param initialDate The date from which to start.
 * @param numDays The number of days to go forward in time.
 * @returns A new date, numDays in the future.
 */
export const dateInXDays = (initialDate: Date, numDays: number): Date => {
  const copiedDate = new Date(initialDate.getTime());
  copiedDate.setDate(initialDate.getDate() + numDays);
  return copiedDate;
};

/**
 * Creates a new date that is a number of minutes ago.
 *
 * @param initialDate The date from which to start.
 * @param numMinutes The number of minutes to go back in time.
 * @returns A new date, numMinutes minutes ago.
 */
export const dateXMinutesAgo = (initialDate: Date, numMinutes: number): Date => {
  return new Date(initialDate.getTime() - numMinutes * 60000);
};

/**
 * Removes seconds and milli-/microseconds from a date.
 *
 * @param initialDate The date for which to remove seconds.
 * @returns The initialDate with second, and decimals set to 0.
 */
export const dateAtStartOfMinute = (initialDate: Date): Date => {
  const copiedDate = new Date(initialDate.getTime());
  copiedDate.setSeconds(0, 0);
  return copiedDate;
};

/**
 * Sets a date to midnight.
 *
 * @param initialDate The date for which to set the time to midnight.
 * @returns The initialDate with hour, minute, second, and decimals set to 0.
 */
export const dateAtMidnight = (initialDate: Date): Date => {
  const copiedDate = new Date(initialDate.getTime());
  copiedDate.setHours(0, 0, 0, 0);
  return copiedDate;
};

/**
 * Sets a date to the first day of the year.
 *
 * @param initialDate The date for which to set the time to the first day of the year.
 * @returns The initialDate with hour, minute, second, and decimals set to 0.
 */
export const dateAtBeginningOfYear = (initialDate: Date): Date => {
  return new Date(initialDate.getFullYear(), 0, 1);
};

/**
 * Sets a date to the start of the month.
 *
 * @param initialDate The date for which to set the time to the first day of the month.
 * @returns The initialDate with day of month set to 1 and hour, minute, second, and decimals set to 0.
 */
export const dateAtFirstDayOfMonth = (initialDate: Date): Date => {
  return new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
};

/**
 * Checks if two dates are on the same day.
 *
 * @param a The first date to check.
 * @param b The second date to check.
 * @returns True if year, month, and day match, false otherwise.
 */
export const sameDay = (a: Date, b: Date): boolean => {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
};

/**
 * Checks if a date is today.
 *
 * @param date The date to check.
 * @returns True if the date is today.
 */
export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

/**
 * Checks if a date is yesterday.
 *
 * @param date The date to check.
 * @returns True if the date is yesterday.
 */
export const isYesterday = (date: Date): boolean => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  );
};

/**
 * Gets the number of days in a given month.
 *
 * @param month The month under consideration.
 * @param year The year under consideration.
 * @returns The number of days in this month.
 */
export const getDaysInMonth = (month: number, year: number): number => {
  // Here January is 1 based
  // Day 0 is the last day in the previous month
  // return new Date(year, month, 0).getDate();
  // Here January is 0 based
  return new Date(year, month + 1, 0).getDate();
};

/**
 * Gets the day of the year of a given date.
 *
 * @param date The date to get the day of the year for.
 * @returns The day of the year.
 */
export const getDayOfYear = (date: Date): number => {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = +date - +start + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  return day;
};

/**
 * Gets a "month key" from a date, e.g.:
 *
 * 202103
 *
 * @param date The date to get a key for.
 * @returns The "month key".
 */
export const getMonthKey = (date: Date): string => {
  return (date.getFullYear() * 100 + date.getMonth()).toString();
};

/**
 * Gets a "day key" from a date, e.g.:
 *
 * 20210427
 *
 * @param date The date to get a key for.
 * @returns The "day key".
 */
export const getDateKey = (date: Date): string => {
  return (date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate()).toString();
};

/**
 * Transforms a "month key" back into a date (at the beginning of the month).
 *
 * @param monthKey The month key to transform back. See {@link getMonthKey}.
 * @returns A Date corresponding to this "month key".
 */
export const getDateFromMonthKey = (monthKey: string): Date => {
  return new Date(parseInt(monthKey.slice(0, 4)), parseInt(monthKey.slice(4, 6)), 1);
};

/**
 * Transforms a "day key" back into a date (at the beginning of the day).
 *
 * @param dateKey The day key to transform back. See {@link getDateKey}.
 * @returns A Date corresponding to this "day key".
 */
export const getDateFromDateKey = (dateKey: string): Date => {
  return new Date(parseInt(dateKey.slice(0, 4)), parseInt(dateKey.slice(4, 6)), parseInt(dateKey.slice(6, 8)));
};
